import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';

export const tooltipTypes = {
  exp: 'exp',
  cvv: 'cvv'
};

const tooltipsExtraWith = 60;

const getTooltipsLeftValue = (tooltipType) => {
  if (tooltipType === tooltipTypes.exp) return 0;
  return tooltipsExtraWith * -1;
};

const getTooltipsBottomArrowRight = (tooltipType) => {
  if (tooltipType === tooltipTypes.exp) return 19 + tooltipsExtraWith;
  return 19;
};

function Tooltips({
  visible, hideTooltip, tooltipData, tooltipType
}) {
  const [tooltipsContainerHeight, setTooltipsContainerHeight] = useState(null);
  const { text } = tooltipData;

  const handleClick = e => {
    if (!(document.tooltipsContainer && document.tooltipsContainer.parentNode.contains(e.target))) {
      hideTooltip();
    }
  };

  useEffect(() => {
    if (visible) {
      setTooltipsContainerHeight(document.tooltipsContainer.clientHeight);
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }
    return () => {};
  }, [visible]);

  const renderTooltips = () => {
    if (!visible) return null;
    return (
      <div
        className={ classes.tooltipsContainer }
        ref={ node => { document.tooltipsContainer = node; } }
        style={
          {
            '--tooltipsContainerHeight': `${ tooltipsContainerHeight * -1 }px`,
            '--tooltipsExtraWith': `${ tooltipsExtraWith }px`,
            '--tooltipsLeftValue': `${ getTooltipsLeftValue(tooltipType) }px`
          }
}
      >
        <div
          className={ classes.tooltipsBottomArrow }
          style={ { '--tooltipsBottomArrowRight': `${ getTooltipsBottomArrowRight(tooltipType) }px` } }
        />
        { text }
      </div>
    );
  };

  return renderTooltips();
}

Tooltips.propTypes = {
  visible: PropTypes.bool.isRequired,
  tooltipType: PropTypes.oneOf(Object.keys(tooltipTypes).map((key) => tooltipTypes[key])),
  hideTooltip: PropTypes.func.isRequired,
  tooltipData: PropTypes.object
};

Tooltips.defaultProps = {
  tooltipType: null,
  tooltipData: {}
};

export default Tooltips;
