import React, { useEffect, useState } from 'react';
import camelCase from 'lodash.camelcase';
import classnames from 'classnames';
import classes from './classes.module.scss';
import { asyncLoadScript, getTextColorForTheme } from '../../config/utils';
import { addCreditCardFormInputTypes, getAddCreditCardFormErrorsText, getPlaceHolders } from '../add_credit_card_form_utils';

const useGetewayCheckoutCom = ({
  config, log, getConfig, submitCardDataHandler, loading, blueSnapSettings, t,
  setSavingBlueSnapCard
}) => {
  const { publicKey } = config;

  const [jsReady, setJsReady] = useState(false);
  const [checkoutErrors, setCheckoutErrors] = useState({ });
  const [status, setStatus] = useState({
    setupComplete: false, addCardClicked: false
  });

  useEffect(() => {
    if (Object.keys(config || {}).length > 0) {
      const isCheckoutComScript = config.jsSrc.includes('checkout.com');
      if (isCheckoutComScript) {
        asyncLoadScript(config.jsSrc, window.Frames).then(() => {
          setJsReady(true);
        });
      }
    }
  }, [config]);

  const setStatusHandler = (value) => {
    setStatus(pendingStatus => ({ ...pendingStatus, ...value }));
  };

  const addCreditCardFormErrorsText = getAddCreditCardFormErrorsText(t);

  const checkoutInputTypes = {
    cardNumber: 'ccn',
    cvv: 'cvv',
    expiryDate: 'exp'
  };

  const setErrors = (errorsKeys) => {
    setCheckoutErrors(pendingCheckoutErrors => {
      let errors = { };
      errorsKeys.forEach((key) => {
        errors = { ...errors, [checkoutInputTypes[key]]: addCreditCardFormErrorsText[key] };
      });
      setStatusHandler({ getewayErrors: { ...pendingCheckoutErrors, ...errors } });
      return { ...pendingCheckoutErrors, ...errors };
    });
  };

  const clearedErrors = (errorType) => {
    setCheckoutErrors(pendingCheckoutErrors => {
      const clearedErrorKeys = Object.keys(pendingCheckoutErrors)
        .filter((err) => err !== errorType);
      const clearedError = {};
      clearedErrorKeys.forEach((key) => { clearedError[key] = pendingCheckoutErrors[key]; });
      setStatusHandler({ getewayErrors: { ...clearedError } });
      return { ...clearedError };
    });
  };

  useEffect(() => {
    const tokenExpiredErrorKey = Object.keys(checkoutErrors)
      .find((k) => checkoutErrors[k] === checkoutErrors.tokenExpired);
    if (tokenExpiredErrorKey) {
      clearedErrors(tokenExpiredErrorKey);
      getConfig();
    }
  }, [checkoutErrors]);

  const setAddCardClicked = () => {
    setStatusHandler({ addCardClicked: true });
  };

  const checkNoValidCheckoutFields = () => {
    const checkoutInputClassNames = {
      cardNumber: 'card-number-frame',
      cvv: 'cvv-frame',
      expiryDate: 'expiry-date-frame'
    };
    const noValidCheckoutFields = [];
    Object.keys(checkoutInputClassNames).forEach((key) => {
      const intupClassName = document.getElementsByClassName(checkoutInputClassNames[key])[0].className;
      if (!intupClassName.includes('frame--valid')) {
        noValidCheckoutFields.push(key);
      }
    });
    return noValidCheckoutFields;
  };

  const addCardHandle = ({
    handleNameZipError, name, zip, setAddCreditCardFormErrorsHandler, isloading, getewayItem
  }) => {
    if (Object.keys(checkoutErrors).length > 0 || isloading || !getewayItem) return;
    setSavingBlueSnapCard(true);
    const isNameZipValid = handleNameZipError({
      name,
      zip,
      setErrors: setAddCreditCardFormErrorsHandler,
      addCreditCardFormInputTypes,
      addCreditCardFormErrorsText
    });
    log('CC Checkout', 'on Saving credit card');
    if (!getewayItem.isCardValid()) {
      setErrors(checkNoValidCheckoutFields());
      setSavingBlueSnapCard(false);
      return;
    }
    getewayItem.submitCard(publicKey).then((cardData) => {
      if (!isNameZipValid) {
        setSavingBlueSnapCard(false);
        return;
      }
      setStatusHandler({ cardData });
      getewayItem.cardholder = {
        name,
        billingAddress: {
          zip
        }
      };
      submitCardDataHandler({ cardData, name, zip });
    }).catch((e) => {
      setErrors(e);
      setSavingBlueSnapCard(false);
    });
  };

  useEffect(() => {
    const defaultCheckoutStyle = {
      base: {
        fontSize: '16px',
        fontWeight: 700,
        height: '100%',
        width: '100%',
        backgroundColor: 'transparent',
        borderRadius : 0,
        padding: 0,
        color: getTextColorForTheme()
      },
      autofill: {
        color: getTextColorForTheme()
      },
      hover: {
        color: getTextColorForTheme()
      },
      focus: {
        color: getTextColorForTheme()
      },
      valid: {
        color: getTextColorForTheme()
      },
      invalid: {
        color: getTextColorForTheme()
      },
      placeholder: {
        base: {
          fontSize: '16px',
          fontWeight: 400
        }
      }
    };
    if (loading || !jsReady || !window.Frames) return () => {};
    log('CC Checkout', 'creating hostedPaymentFields');
    window.Frames.init({
      publicKey,
      // debug: true,
      localization: {
        cardNumberPlaceholder: getPlaceHolders({
          placeHolders: blueSnapSettings.placeHolders,
          placeHolderType: checkoutInputTypes.cardNumber
        }),
        cvvPlaceholder: getPlaceHolders({
          placeHolders: blueSnapSettings.placeHolders, placeHolderType: checkoutInputTypes.cvv
        }),
        expiryMonthPlaceholder: getPlaceHolders({
          placeHolders: blueSnapSettings.placeHolders,
          placeHolderType: checkoutInputTypes.expiryDate
        }).split('/')[0] || ' ',
        expiryYearPlaceholder: getPlaceHolders({
          placeHolders: blueSnapSettings.placeHolders,
          placeHolderType: checkoutInputTypes.expiryDate
        }).split('/')[1] || ' '
      },
      style: blueSnapSettings.checkoutStyle || defaultCheckoutStyle
    });
    window.Frames.addEventHandler(window.Frames.Events.READY, () => {
      setStatusHandler({ setupComplete: true });
    });
    window.Frames.addEventHandler(window.Frames.Events.FRAME_ACTIVATED, () => {
      setStatusHandler({ setupComplete: true });
    });
    window.Frames.addEventHandler(window.Frames.Events.CARD_BIN_CHANGED, ({ scheme }) => {
      setStatusHandler({ cardNameType: scheme });
    });
    window.Frames.addEventHandler(window.Frames.Events.FRAME_BLUR, () => {
      setStatusHandler({ fieldFocus: '' });
    });
    window.Frames.addEventHandler(window.Frames.Events.FRAME_FOCUS, ({ element }) => {
      const camelCaseElement = camelCase(element);
      setStatusHandler({ fieldFocus: checkoutInputTypes[camelCaseElement] });
    });
    window.Frames.addEventHandler(window.Frames.Events.FRAME_VALIDATION_CHANGED, ({
      element, isValid
    }) => {
      const camelCaseElement = camelCase(element);
      if (!isValid) {
        setErrors([camelCaseElement]);
      }
      if (isValid) {
        clearedErrors(checkoutInputTypes[camelCaseElement]);
      }
    });
    const blueSnapStatusProps = {
      addCardHandle, setAddCardClicked, getewayItem: window.Frames
    };
    setStatusHandler(blueSnapStatusProps);

    return () => {
      window.Frames.removeAllEventHandlers(window.Frames.Events.READY);
      window.Frames.removeAllEventHandlers(window.Frames.Events.CARD_BIN_CHANGED);

      window.Frames.removeAllEventHandlers(window.Frames.Events.FRAME_BLUR);
      window.Frames.removeAllEventHandlers(window.Frames.Events.FRAME_FOCUS);
      window.Frames.removeAllEventHandlers(window.Frames.Events.FRAME_VALIDATION_CHANGED);
      setStatus({});
    };
  }, [jsReady, publicKey, config]);

  const getInputClassName = (inputClassName) => classnames(inputClassName, classes.checkoutDiv);

  const cardNumberInput = (
    <div className={ getInputClassName('card-number-frame') } />
  );

  const dateExpiredInput = (
    <div className={ getInputClassName('expiry-date-frame') } />
  );

  const securityInput = (
    <div className={ getInputClassName('cvv-frame') } />
  );

  const fraudFrame = (
    <></>
  );

  const getewayFields = {
    cardNumberInput, dateExpiredInput, securityInput, fraudFrame
  };

  return { getewayFields, getewayStatus: status };
};

export default useGetewayCheckoutCom;
