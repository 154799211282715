import React from 'react';
import classnames from 'classnames';
import classes from './classes.module.scss';
import CreditCardImg from './credit_card_img';
import Tooltips from './tooltips/tooltips';

const ccInfoImg = require('./img/cc_info.svg');

export const tagIds = {
  ccn: 'ccn',
  exp: 'exp',
  cvv: 'cvv'
};

export const getAddCreditCardFormErrorsText = (t) => ({
  cName: t('blueSnap.enter_card_name'),
  invalidCcNumber: t('blueSnap.incorrect_card_number'),
  invalidCvv: t('blueSnap.incorrect_cvv_number'),
  invalidExpDate: t('blueSnap.incorrect_expiration_date'),
  cZip: t('blueSnap.enter_zip'),
  invalidInput: t('blueSnap.incorrect_value'),
  expiredToken: t('blueSnap.token_expired'),
  paymentError: t('blueSnap.payment_error'),
  tokenExpired: 'token expired',
  cardNumber: t('blueSnap.incorrect_card_number'),
  cvv: t('blueSnap.incorrect_cvv_number'),
  expiryDate: t('blueSnap.incorrect_expiration_date')
});

const selectedStylee = (error) => {
  if (error) return { borderColor: '#F55151' };
  return { borderColor: 'var(--app-color)', borderWidth: '1px' };
};

export const getPlaceHolders = ({ placeHolders, placeHolderType }) => {
  if (!placeHolders || !placeHolders[placeHolderType]) return ' ';
  return placeHolders[placeHolderType];
};

export const renderLabel = ({ inputLabels, inputType }) => {
  if (!inputLabels || !inputLabels[inputType]) return null;
  return <div className={ classes.labelContainer }>{ inputLabels[inputType] }</div>;
};

export const renderError = (value) => {
  if (!value) return null;
  return <div className={ classes.error }>{ value }</div>;
};

const canSaveZip = ({ zip }) => {
  const re = /^(?!\s*$).+[a-zA-Z0-9 -]+$/;
  return re.test(zip);
};

export const canSave = ({
  name, zip, getewayErrors = {}
}) =>  !!name && !!canSaveZip({ zip })
&& !getewayErrors.ccn && !getewayErrors.exp && !getewayErrors.cvv;

const handleKeyDownZip = ({
  event, addCard, name, zip, blueSpanErrors
}) => {
  if (event.target.id === 'zip') {
    if (canSave({
      name, zip, blueSpanErrors
    })) {
      event.target.blur();
      addCard();
    } else event.target.blur();
  }
};

export const handleKeyDown = ({
  event, addCard, name, zip, blueSpanErrors
}) => {
  if (event.key === 'Enter') {
    if (event.target.id === 'nameOfCard') event.target.blur();
    handleKeyDownZip({
      event, addCard, name, zip, blueSpanErrors
    });
  }
};

export const getLoading = ({
  loading, setupComplete
}) => loading || !setupComplete;

export const getBlueSnapFieldFocusStyle = ({ fieldFocus, tagType, error }) => {
  if (fieldFocus === tagType || error) return selectedStylee(error);
  return {};
};

export const handleNameZipError = ({
  name, zip, setErrors, addCreditCardFormInputTypes, addCreditCardFormErrorsText
}) => {
  let errors = {};
  if (!name) {
    errors = {
      ...errors,
      [addCreditCardFormInputTypes.cName]:
        addCreditCardFormErrorsText[addCreditCardFormInputTypes.cName]
    };
  }
  if (
    !canSaveZip({ zip })
  ) {
    errors = {
      ...errors,
      [addCreditCardFormInputTypes.cZip]:
        addCreditCardFormErrorsText[addCreditCardFormInputTypes.cZip]
    };
  }
  if (setErrors) setErrors(errors);
  const isNameZipValid = Object.keys(errors).length === 0;
  return isNameZipValid;
};

export const getInputClasses = ({ error, loading }) => {
  if (loading) return classnames(classes.defaultInput, classes.skeleton);
  if (error) return classes.defaultInputError;
  return classes.defaultInput;
};

export const renderCreditCardImg = ({ blueSnapSettings, cardNameType }) => {
  if (!blueSnapSettings.needShowCreditCardImg) return null;
  return (
    <CreditCardImg
      type={ cardNameType }
      style={ { marginRight: '11px', width: '30px' } }
    />
  );
};

export const renderTooltipHandler = ({
  tooltipType, blueSnapSettings, visibleTooltip, hideTooltip, showTooltip
}) => {
  if (!blueSnapSettings.tooltips || !blueSnapSettings.tooltips[tooltipType]) return null;
  const infoButtonClick = () => {
    if (visibleTooltip === tooltipType) {
      hideTooltip();
      return;
    }
    showTooltip(tooltipType);
  };
  return (
    <>
      <Tooltips
        visible={ visibleTooltip === tooltipType }
        hideTooltip={ hideTooltip }
        tooltipData={ blueSnapSettings.tooltips[tooltipType] }
        tooltipType={ tooltipType }
      />
      <button
        type="button"
        className={ classes.infoButton }
        id={ tooltipType }
        onClick={ infoButtonClick }
        tabIndex={ -1 }
      >
        <img src={ ccInfoImg } alt="" className={ classes.infoImg } />
      </button>
    </>
  );
};

export const addCreditCardFormInputTypes = {
  cName: 'cName',
  ccn: 'ccn',
  cvv: 'cvv',
  exp: 'exp',
  cZip: 'cZip'
};

export const defaultBlueSnapStyle = {
  input: {
    'font-size': '16px',
    'font-weight': 500,
    height: '100%',
    width: '100%',
    'background-color': 'transparent',
    'border-radius' : 0,
    padding: 0
  },
  '::placeholder': {
    'font-size': '16px',
    'font-weight': 400
  }
};
