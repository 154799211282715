import useGetewayBluesnap from './use_geteway_bluesnap';
import useGetewayCheckoutCom from './use_geteway_checkout_com';

const BLUESNAP_GATAWAY_NAMES = [
  'bluesnap',
  'blusn_po'
];

const CHEKOUTCOM_GATAWAY_NAMES = [
  'ckout_pg',
  'ckout_po',
  'ckout_ka'
];

const useGetewayLoader = ({
  config, log, loading, blueSnapSettings, t, setSavingBlueSnapCard, getConfig, submitCardDataHandler
}) => {
  let geteway = '';

  const getewayProps = {
    config, log, loading, blueSnapSettings, t, setSavingBlueSnapCard, getConfig, submitCardDataHandler
  };

  const getewayBluesnap = useGetewayBluesnap({ ...getewayProps });

  const getewayCheckoutCom = useGetewayCheckoutCom({ ...getewayProps });

  if (Object.keys(config || {}).length > 0) {
    if (BLUESNAP_GATAWAY_NAMES.includes(config.gatewayName)) {
      geteway = getewayBluesnap;
    } else if (CHEKOUTCOM_GATAWAY_NAMES.includes(config.gatewayName)) {
      geteway = getewayCheckoutCom;
    }
  }
  return { ...geteway };
};

export default useGetewayLoader;
